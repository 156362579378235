import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';

import { Swiper, Autoplay, Pagination, EffectFade } from 'swiper/js/swiper.esm.js';
Swiper.use([Autoplay, Pagination, EffectFade]);

import SimpleLightbox from "simplelightbox";

/*Se inicializa el plugin de simple-lightbox*/
if ($.contains(document.body, document.getElementById('isValidBlockGallery'))) {
    new SimpleLightbox('.gallery-lightbox', {
        captionSelector: 'self',
    });
}

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
// $('header').load("header.html");
// $('footer').load("footer.html");
// $('.block-newletter').load("block-newletter.html");
// $('.block-divisions').load("block-divisions.html");
// $('.block-avintia-services').load("block-avintia-services.html");
// $('.block-services-study-cases').load("block-services-study-cases.html");

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiperMain = new Swiper('#slider-main', {
        loop     : true,
        autoplay : {
            delay    : 5000,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    $(document).on('click', '.block-banner-main .btn', function (event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiperMain.slidePrev();
        } else {
            mySwiperMain.slideNext();
        }
    });
}

/*Script para el slider de personas nuestros valores*/
if ($.contains(document.body, document.getElementById('slider-people-values'))) {

    var mySwiperPeopleValues = new Swiper('#slider-people-values', {
        speed: 1000,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true
        }
    });

    mySwiperPeopleValues.on('slideChange', function () {
        $(`#slider-people-values .swiper-pagination-bullet`).removeClass('active');
        $(`#slider-people-values-pagination-${this.realIndex}`).addClass('active');
    });
}

/*Script para el slider de personas nuestros valores*/
if ($.contains(document.body, document.getElementById('slider-summary-home'))) {

    var mySwiperSummaryHome = new Swiper('#slider-summary-home', {
        loop: false,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });
}

var aux;
$(document).on('click', '.navbar-collapse .nav-link', function (event) {
    event.preventDefault();

    var item = $(this).attr('href');

    $('.navbar-collapse .nav-link').removeClass('active');
    $(this).addClass('active');

    var currentUrl = window.location.href;

    if (item.substr(0, 1) == '#') {
        if (aux == item) {
            $(item).hide();
            $('#bg-menu').hide();
            if (currentUrl.substr(0, 1) != '#') {
                $('.navbar-collapse .nav-link').removeClass('active');
                $(`.navbar-collapse a[href^="${currentUrl}"]`).addClass('active');
            }
            aux = false;
        } else {
            $('.block-navbar-collapse .multi-collapse').hide();
            $(item).fadeIn();
            $('#bg-menu').fadeIn();
            aux = item;
        }
    }else{
        window.location.href = item;
    }

});

function number_format(amount, decimals) {

    amount += ''; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0)
        return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = '' + amount.toFixed(decimals);

    var amount_parts = amount.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
        amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');

    return amount_parts.join('.');
}

$('.count').each(function () {
    $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
    }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
            $(this).text(number_format(Math.ceil(now), 0));
        }
    });
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();